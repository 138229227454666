import { Component } from '@angular/core';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { SystemService } from '../../../lockvue-ng-sdk';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <footer>
      Powered by
      <div class="lockview-footer-logo"></div>
    </footer>
  `,
})
export class FooterComponent {}
