<!-- have tried only icon button but it doesn't look good. Does not center insied the table cell. -->
<button
  nbButton
  (mouseenter)="hover()"
  status="primary"
  outline
  size="small"
  [nbPopover]="moreInfo"
  [nbPopoverContext]="value"
  nbPopoverPlacement="left"
  nbPopoverAdjustment="noop"
  nbPopoverTrigger="hover">
  More
</button>
<ng-template #moreInfo let-value>
  <div>
    <span class="paragraph">Lock Id: {{ value.Id }}</span
    ><br />
    <span class="paragraph">Last Battery: {{ value.LastBatteryLevel }}</span
    ><br />
    <span class="paragraph"
      >Last Unlocked By:
      {{ value.LastUnlockedBy ? value.LastUnlockedBy.DisspanlayName : 'Unknown' }}</span
    ><br />
    <span class="paragraph">Last Unlocked At: {{ getFormattedDate(value.LastUnlockedAtUtc) }}</span
    ><br />
    <span class="paragraph">Offline Enabled: {{ value.OfflineEnabled ? 'Yes' : 'No' }}</span
    ><br />
    <span class="paragraph">Unlock Algorithm: {{ value.UnlockAlgorithm }}</span
    ><br />
    <span *ngIf="codes && codes.length > 0" class="paragraph"
      >Manual Unlock Code: <br />
      <span *ngFor="let code of codes">
        {{ code.SerialNumber }} : {{ code.ManualUnlockCode }}
      </span>
    </span>
  </div>
</ng-template>
