/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EventAction = 'Locked' | 'Unlocked' | 'Created' | 'Modified' | 'Deleted' | 'Granted' | 'Revoked' | 'Battery' | 'Location' | 'Added' | 'Removed' | 'OfflineLocked' | 'OfflineUnlocked' | 'Ownership_Transferred' | 'Billing_Status_Changed' | 'Shackle_Removed' | 'Heartbeat' | 'Peg_Unlocked' | 'Peg_Removed' | 'Peg_Returned' | 'Door_Unlocked' | 'Unlock_All' | 'Request' | 'Lock_Status' | 'External_Battery';

export const EventAction = {
    Locked: 'Locked' as EventAction,
    Unlocked: 'Unlocked' as EventAction,
    Created: 'Created' as EventAction,
    Modified: 'Modified' as EventAction,
    Deleted: 'Deleted' as EventAction,
    Granted: 'Granted' as EventAction,
    Revoked: 'Revoked' as EventAction,
    Battery: 'Battery' as EventAction,
    Location: 'Location' as EventAction,
    Added: 'Added' as EventAction,
    Removed: 'Removed' as EventAction,
    OfflineLocked: 'OfflineLocked' as EventAction,
    OfflineUnlocked: 'OfflineUnlocked' as EventAction,
    OwnershipTransferred: 'Ownership_Transferred' as EventAction,
    BillingStatusChanged: 'Billing_Status_Changed' as EventAction,
    ShackleRemoved: 'Shackle_Removed' as EventAction,
    Heartbeat: 'Heartbeat' as EventAction,
    PegUnlocked: 'Peg_Unlocked' as EventAction,
    PegRemoved: 'Peg_Removed' as EventAction,
    PegReturned: 'Peg_Returned' as EventAction,
    DoorUnlocked: 'Door_Unlocked' as EventAction,
    UnlockAll: 'Unlock_All' as EventAction,
    Request: 'Request' as EventAction,
    LockStatus: 'Lock_Status' as EventAction,
    ExternalBattery: 'External_Battery' as EventAction
};