import { Component } from '@angular/core';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { SystemService } from '../../../lockvue-ng-sdk';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
        <nb-sidebar-footer>
          <div class="text-center">Version {{ feVersion }} / {{ beVersion }}</div>
        </nb-sidebar-footer>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  public feVersion: string;
  public beVersion: string;

  constructor(
    private configService: LockvueConfigProviderService,
    private systemService: SystemService,
  ) {
    this.feVersion = this.configService.getDashboardVersion();
    this.systemService.systemVersion().subscribe(data => {
      this.beVersion = data;
    });
  }
}
