/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserModel { 
    UserId?: string;
    DisplayName?: string;
    PhoneNumber?: string;
    Company?: string;
    Country?: string;
    MemberSinceUtc?: Date;
    ClientId?: string;
    EnableYBIIntegration?: boolean;
    EnableHOMheroIntegration?: boolean;
    EnableCodes?: boolean;
    EnableNms?: boolean;
    UseDarkTheme?: boolean;
}