import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileService, UserModel } from '../../../lockvue-ng-sdk';
import { UserProfileUpdateService } from '../../../services/user-profile-update.service';
import { BrowserSupportService } from '../../../services/browser-support.service';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKeys } from '../../../services/constants/LocalStorageKeys';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userDisplayName: string;
  isSupportedBrowser: boolean;
  isDropdownVisible = !environment.production;

  themes = [
    {
      value: 'lockvue',
      name: 'LockVue',
    },
    {
      value: 'lockvue_dark',
      name: 'LockVue Dark',
    },
    {
      value: 'dormakaba',
      name: 'dormakaba',
    },
    {
      value: 'sls',
      name: 'SLS',
    },
    {
      value: 'allied_dark',
      name: 'Allied Dark',
    },
    {
      value: 'allied_light',
      name: 'Allied Light',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    { title: 'Profile', data: 'profile', link: '/settings/profile', icon: 'ion-person' },
    { title: 'Log out', data: 'logout', link: '/auth/logout', icon: 'ion-log-out' },
  ];
  tag = 'lv-user-context-menu';

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private authService: OidcSecurityService,
    private breakpointService: NbMediaBreakpointsService,
    private profileService: ProfileService,
    private profileUpdateService: UserProfileUpdateService,
    private browserSupportService: BrowserSupportService,
    private configService: LockvueConfigProviderService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.authService.checkAuth().subscribe(({ isAuthenticated, userData }) => {
      if (isAuthenticated) {
        this.user = userData; // get decoded payload from the token
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => (this.currentTheme = themeName));

    this.getUserProfile();
    this.profileUpdateService.onProfileUpdated.subscribe(x => this.getUserProfile());

    this.isSupportedBrowser = this.browserSupportService.isSupportedBrowser();
  }

  getUserProfile() {
    this.profileService
      .profileMy()
      .pipe(takeUntil(this.destroy$))
      .subscribe(profile => {
        this.userDisplayName = profile.DisplayName;
        const aliasName = this.localStorage.getItem(LocalStorageKeys.Alias);
        this.userDisplayName = aliasName ? aliasName : this.userDisplayName;
        const themeName = this.configService.getTheme(profile.UseDarkTheme);
        this.themeService.changeTheme(themeName);
        this.onProfileDataReceived(profile); // ensure no circular / recursive calls
      });
  }

  onProfileDataReceived(profile: UserModel) {
    if (
      !profile.DisplayName &&
      !profile.PhoneNumber &&
      !profile.Company &&
      (this.user.DisplayName || this.user.PhoneNumber || this.user.Company || this.user.Country)
    ) {
      console.warn('User profile was not found, generating it from the user details available.');
      this.profileService
        .profileUpdate({
          Company: this.user.Company,
          Country: this.user.Country,
          DisplayName: this.user.DisplayName,
          PhoneNumber: this.user.PhoneNumber,
          EnableCodes: profile.EnableCodes,
          EnableHOMheroIntegration: profile.EnableHOMheroIntegration,
          EnableYBIIntegration: profile.EnableYBIIntegration,
          UseDarkTheme: profile.UseDarkTheme,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(x => this.getUserProfile()); // ensure no circular / recursive calls
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
