import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LockPermissionUIModel } from '../../models/LockPermissionUIModel';
import { LockPermissionsService } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { LocalDataSource } from 'ng2-smart-table';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';

@Component({
  selector: 'lockvue-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss'],
})
export class PermissionsTableComponent implements OnInit {
  tableSettings: any;
  private _activePermissions: LockPermissionUIModel[];
  permissionsSource: LocalDataSource;

  // Currently selected permission (using checkboxes)
  selectedPermissions: LockPermissionUIModel[];

  // Row currently in focus
  permissionOnFocus: LockPermissionUIModel;

  @Input() set permissions(value: LockPermissionUIModel[]) {
    this._activePermissions = value;
    this.permissionsSource = new LocalDataSource(value);
    // subscriber does not work when put under onInit
    this.permissionsSource.onChanged().subscribe(event => {
      if (event.action === 'filter' || event.action === 'sort') {
        this.permissionsSource.getFilteredAndSorted().then(data => {
          this.tableFilter.emit(data);
        });
      }
    });
    this.initTableSettings(); // refresh table settings
  }

  get permissions(): LockPermissionUIModel[] {
    return this._activePermissions;
  }

  @Input() hideFilters: boolean = false;

  @Input() showMultiSelect: boolean = true;

  @Output() selectedPermissionsChange: EventEmitter<LockPermissionUIModel[]>;

  @Output() tableFilter: EventEmitter<LockPermissionUIModel[]> = new EventEmitter();

  constructor(
    private permissionsService: LockPermissionsService,
    private notificationsService: NotificationMessagesService,
    private confirmDialogService: ConfirmDialogService,
    private tableSettingsProvider: TableSettingsProviderService,
  ) {
    this.selectedPermissionsChange = new EventEmitter();
  }

  ngOnInit() {
    this.initTableSettings();
  }

  onRowSelect(event: any) {
    this.permissionOnFocus = event.data;
  }

  onUserRowSelect(event: any) {
    const selectedRows = event.selected;
    this.selectedPermissions = selectedRows;
    this.selectedPermissionsChange.emit(selectedRows);
  }

  private initTableSettings() {
    this.tableSettings = this.tableSettingsProvider.getPermissionPageSettings(
      this.showMultiSelect,
      this.hideFilters,
    );
  }
}
