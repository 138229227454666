<div class="table-container">
  <ng2-smart-table
    [settings]="tableSettings"
    [source]="permissionsSource"
    (rowHover)="onRowSelect($event)"
    (userRowSelect)="onUserRowSelect($event)">
  </ng2-smart-table>
</div>
<div>
  <ngx-slide-out [open]="false">
    <span *ngIf="!permissionOnFocus"> Please select a lock permission to view details. </span>

    <div *ngIf="permissionOnFocus">
      <lockvue-permission-info [value]="permissionOnFocus"></lockvue-permission-info>
    </div>
  </ngx-slide-out>
</div>
