/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminLockActivationModel } from '../model/adminLockActivationModel';
import { BillableFlagModel } from '../model/billableFlagModel';
import { ChangeLockTypeModel } from '../model/changeLockTypeModel';
import { LockDetailsWithOwnerModel } from '../model/lockDetailsWithOwnerModel';
import { LockReplaceModel } from '../model/lockReplaceModel';
import { ManualUnlockCodeRequestModel } from '../model/manualUnlockCodeRequestModel';
import { OwnershipTransferAdminModel } from '../model/ownershipTransferAdminModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LocksAdminService {

    protected basePath = 'http://localhost:5192/api/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates a valid available lock against the given user
     * 
     * @param body Model containing valid lock activation properties.
Important: LockType must be the valid &quot;UnlockAlgorithm&quot; value of the lock.(HelperServices.ActionContext.ActionContext)HelperServices.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminActivate(body?: AdminLockActivationModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksAdminActivate(body?: AdminLockActivationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksAdminActivate(body?: AdminLockActivationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksAdminActivate(body?: AdminLockActivationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/admin/Locks/Activate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminAll(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsWithOwnerModel>>;
    public locksAdminAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsWithOwnerModel>>>;
    public locksAdminAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsWithOwnerModel>>>;
    public locksAdminAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LockDetailsWithOwnerModel>>('get',`${this.basePath}/admin/Locks/All`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Makes an existing activated lock billable or non-billable
     * 
     * @param body Model containing a valid lock-id and the billable flag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminBillable(body?: BillableFlagModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminBillable(body?: BillableFlagModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminBillable(body?: BillableFlagModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminBillable(body?: BillableFlagModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/admin/Locks/Billable`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes the lock type of the given lock.
     * 
     * @param body Model containing new LockType details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminChangeLockType(body?: ChangeLockTypeModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminChangeLockType(body?: ChangeLockTypeModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminChangeLockType(body?: ChangeLockTypeModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminChangeLockType(body?: ChangeLockTypeModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/admin/Locks/ChangeLockType`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the lock identified by the given unique lock id.
     * 
     * @param Id A valid lock id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminDelete(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminDelete(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminDelete(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminDelete(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/admin/Locks/Delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the manual unlock code such as the quick-click code of a lock.
     * 
     * @param Id A valid lock-id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminGetManualUnlockCode(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksAdminGetManualUnlockCode(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksAdminGetManualUnlockCode(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksAdminGetManualUnlockCode(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/admin/Locks/GetManualUnlockCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replaces an existing lock with the new lock while keeping the lock-id  and all lock permissions and other relationships intact.    Note: Some relationships may need to be manually updated such as associated Hubs.
     * 
     * @param body Model containing lock replacement details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminReplaceLock(body?: LockReplaceModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminReplaceLock(body?: LockReplaceModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminReplaceLock(body?: LockReplaceModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminReplaceLock(body?: LockReplaceModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/admin/Locks/ReplaceLock`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the manual unlock code such as the quick-click code of a lock.
     * 
     * @param body Model containing manual unlock code and a valid lock-id.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminSetManualUnlockCode(body?: ManualUnlockCodeRequestModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminSetManualUnlockCode(body?: ManualUnlockCodeRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminSetManualUnlockCode(body?: ManualUnlockCodeRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminSetManualUnlockCode(body?: ManualUnlockCodeRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/admin/Locks/SetManualUnlockCode`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfers lock ownership to a new user
     * 
     * @param body Model containing LockId, OwnerId and NewOwnerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksAdminTransferOwnership(body?: OwnershipTransferAdminModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksAdminTransferOwnership(body?: OwnershipTransferAdminModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksAdminTransferOwnership(body?: OwnershipTransferAdminModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksAdminTransferOwnership(body?: OwnershipTransferAdminModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/admin/Locks/TransferOwnership`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
