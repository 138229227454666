/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseLockEditModel } from '../model/baseLockEditModel';
import { LockActivationModel } from '../model/lockActivationModel';
import { LockDetailsModel } from '../model/lockDetailsModel';
import { LockDetailsWithPermissionModel } from '../model/lockDetailsWithPermissionModel';
import { LockShareBulkModel } from '../model/lockShareBulkModel';
import { LockShareModel } from '../model/lockShareModel';
import { LockShareWithTeamModel } from '../model/lockShareWithTeamModel';
import { LockShareWithTeamResponseModel } from '../model/lockShareWithTeamResponseModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LocksService {

    protected basePath = 'http://localhost:5192/api/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activates a lock.
     * 
     * @param body Object containing lock activation details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksActivate(body?: LockActivationModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locksActivate(body?: LockActivationModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locksActivate(body?: LockActivationModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public locksActivate(body?: LockActivationModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/Locks/Activate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with a list of users with given permissions
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksBulkShare(body?: LockShareBulkModel, observe?: 'body', reportProgress?: boolean): Observable<LockShareWithTeamResponseModel>;
    public locksBulkShare(body?: LockShareBulkModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LockShareWithTeamResponseModel>>;
    public locksBulkShare(body?: LockShareBulkModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LockShareWithTeamResponseModel>>;
    public locksBulkShare(body?: LockShareBulkModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LockShareWithTeamResponseModel>('post',`${this.basePath}/Locks/BulkShare`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the lock identified by the given unique lock id.    Note: The caller must be the owner of the lock
     * 
     * @param Id A valid lock id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksDelete(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksDelete(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksDelete(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksDelete(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/Locks/Delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates lock details with the given new values. Please note that all existing values  will be replaced by the new values (including nulls).    Please note that the user must be either the owner or an administrator of the lock.
     * 
     * @param body Model containing new values. Please copy existing values if no update is intended.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksEdit(body?: BaseLockEditModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksEdit(body?: BaseLockEditModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksEdit(body?: BaseLockEditModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksEdit(body?: BaseLockEditModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Locks/Edit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of current lock subtypes in the system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksLockSubtypes(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public locksLockSubtypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public locksLockSubtypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public locksLockSubtypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/Locks/LockSubtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of current lock types in the system
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksLocktypes(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public locksLocktypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public locksLocktypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public locksLocktypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/Locks/Locktypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all locks owned by the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksOwn(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsModel>>;
    public locksOwn(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsModel>>>;
    public locksOwn(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsModel>>>;
    public locksOwn(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LockDetailsModel>>('get',`${this.basePath}/Locks/Own`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with another user with given permissions
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShare(body?: LockShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksShare(body?: LockShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksShare(body?: LockShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksShare(body?: LockShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Locks/Share`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with another user with given permissions using an auth-code  without requiring the user&#x27;s email address.    Please note that you cannot share a lock with a unique code using admin permissions.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShareWithCode(body?: LockShareModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public locksShareWithCode(body?: LockShareModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public locksShareWithCode(body?: LockShareModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public locksShareWithCode(body?: LockShareModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Locks/ShareWithCode`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Shares the lock with the users in a team
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksShareWithTeam(body?: LockShareWithTeamModel, observe?: 'body', reportProgress?: boolean): Observable<Array<LockShareWithTeamResponseModel>>;
    public locksShareWithTeam(body?: LockShareWithTeamModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockShareWithTeamResponseModel>>>;
    public locksShareWithTeam(body?: LockShareWithTeamModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockShareWithTeamResponseModel>>>;
    public locksShareWithTeam(body?: LockShareWithTeamModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<LockShareWithTeamResponseModel>>('post',`${this.basePath}/Locks/ShareWithTeam`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all locks shared with the current user by their owners
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locksSharedWithMe(observe?: 'body', reportProgress?: boolean): Observable<Array<LockDetailsWithPermissionModel>>;
    public locksSharedWithMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LockDetailsWithPermissionModel>>>;
    public locksSharedWithMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LockDetailsWithPermissionModel>>>;
    public locksSharedWithMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // authentication (JWT) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LockDetailsWithPermissionModel>>('get',`${this.basePath}/Locks/SharedWithMe`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
